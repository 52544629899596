// Import React
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React, { useEffect } from 'react';
import { BenefitCard, Navbar, PageFooter } from '../components';
// import our styles
import '../style/style.scss';

export const query = graphql`
  query BenefitsQuery($type: String!, $limit: Int!, $skip: Int!) {
    allPrismicBenefit(
      filter: { data: { benefit_type: { eq: $type } } }
      limit: $limit
      skip: $skip
      sort: { fields: data___benefit_provider___uid }
    ) {
      edges {
        node {
          uid
          data {
            benefit_provider {
              uid
              type
              document {
                ... on PrismicProviders {
                  id
                  data {
                    provider_name
                  }
                }
              }
            }
            benefit_type
            benefit_list_image {
              url
            }
            benefit_name
            body {
              ... on PrismicBenefitBodyBenefitBodyText {
                id
                slice_type
                primary {
                  benefit_body_text_content {
                    raw
                  }
                }
              }
            }
          }
          type
        }
      }
      pageInfo {
        pageCount
        perPage
        totalCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const Benefits = ({ data, pageContext }) => {
  useEffect(() => {}, []);

  if (!data) return null;

  const { type, previousPage, nextPage, settings } = pageContext;
  const { pageInfo, edges } = data.allPrismicBenefit;

  const previousButton = pageInfo.hasPreviousPage && (
    <a href={previousPage} rel="prev" className="btn btn-primary">
      ← Previous Page
    </a>
  );

  const nextButton = pageInfo.hasNextPage && (
    <a href={nextPage} rel="next" className="btn btn-primary">
      Next Page →
    </a>
  );

  return (
    <main className="page has-parent">
      <Navbar {...settings.data} />
      <div id="benefits" className="benefits">
        {type && (
          <h1 className="text-center text-capitalize">{type} Benefits</h1>
        )}
        <div className="container benefits-container">
          <div className="row">
            {edges.map((item, idx) => {
              return (
                <div className="col-12 col-lg-4 p-4" key={idx}>
                  <BenefitCard
                    data={item.node.data}
                    uid={item.node.uid}
                    type={type}
                  />
                </div>
              );
            })}
          </div>
          <div className=" text-center">
            {previousButton} {nextButton}
          </div>
        </div>
      </div>
      <PageFooter />
    </main>
  );
};

export default withPreview(Benefits);
